import data from "../../utils/Event/otherEvents.json";
import ProgressBar from "./progressBar";
import FinancialMetrics from "./financialMetrics";
import CustomButton from "../Common/button.jsx";

const OtherEvents = () => {
  const events = data;
  return (
    <div className="container mx-auto px-4 md:px-36 pt-6 md:pt-20 w-full">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {events.map((event) => (
          <div
            key={event.id}
            className="flex flex-col bg-white rounded-lg shadow-md overflow-hidden h-full"
          >
            <img
              className="rounded-t-lg w-full h-68 object-cover" // Fixed height for images
              src={event.image}
              alt={event.title}
            />
            <div className="flex flex-col justify-between flex-grow p-4">
              <div>
                <h5 className="text-xl line-clamp-2">{event.title}</h5>
                <p className="text-base mt-4 line-clamp-3">
                  {event.description}
                </p>
              </div>
              <div>
                <ProgressBar progress={(event.raised / event.goal) * 100} />
                <br />
                <FinancialMetrics goal={event.goal} raised={event.raised} />
                <div className="flex justify-center py-4 ">
                  <CustomButton
                    text="Donate"
                    onClick={undefined} // Add onClick event
                    width="w-2/4"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherEvents;
