import React from "react";
import data from "../../utils/Event/donate.json";
import CustomButton from "../Common/button.jsx";

const donateSec = () => {
  const event = data[0];

  return (
    <div className="container mx-auto px-4 md:px-36 pt-10 md:pt-20 w-full">
      <div className="flex flex-col md:flex-row  items-center overflow-hidden">
        <div className="md:w-1/2  text-left">
          <img src={event.image} alt="Support" />
        </div>
        <div className="py-8 md:p-12 item-end md:w-3/5">
          <h2 className="text-6xl font-medium text-gray-800 mb-4">
            Cause we care
          </h2>
          <p className="text-gray-600 mb-6 text-lg">
            For over 50 years, our charity has provided vital services to those
            in need in our community - from food security, housing assistance,
            job training to youth development programs. With your gift, you help
            empower hundreds of individuals and families build a brighter future
            each year. Every contribution makes an impact. Join us in improving
            lives.
          </p>
          <CustomButton text="Donate" onClick={undefined} />
        </div>
      </div>
    </div>
  );
};

export default donateSec;
