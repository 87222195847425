import React , { useState, useEffect } from 'react';
import Logo from '../../assets/logo.webp';

const NavItem = ({ href, children, isActive }) => {
  return (
    <a href={href} className={`text-gray-700 hover:text-gray-900 font-lexend text-lg ${isActive ? 'text-white md:text-gray-900' : 'text-gray-200 md:text-[#727272]'}`}>
      {children}
    </a>
  );
};


const Navbar = () => {
  const [activeNavItem, setActiveNavItem] = useState(window.location.pathname);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
useEffect(() => {
    setActiveNavItem(window.location.pathname);
  }, []);
  return (
    <nav className="flex justify-center items-center py-4">
     <div className="flex justify-between items-center py-4">
  <div className="hidden md:flex space-x-4">
    {/* Menu Items Left */}
    <NavItem href="/" isActive={activeNavItem === '/'}>Home</NavItem>
    <NavItem href="/events" isActive={activeNavItem === '/events'}>Events</NavItem>
    <NavItem href="/business" isActive={activeNavItem === '/business'}>Business</NavItem>
  </div>
  
  <div className="flex items-center mx-4">
    {/* Logo */}
    <img src={Logo} alt="Logo" className="h-12 w-12" />
  </div>

  <div className="hidden md:flex space-x-4">
    {/* Menu Items Right */}
    <NavItem href="/news" isActive={activeNavItem === '/news'}>News</NavItem>
    <NavItem href="/about" isActive={activeNavItem === '/about'}>About Us</NavItem>
    <NavItem href="/contact" isActive={activeNavItem === '/contact'}>Contact</NavItem>
  </div>

  <div className="md:hidden fixed top-8 right-4"> {/* Use ml-auto here */}
    {/* Hamburger Menu Icon */}
    <button onClick={toggleDrawer}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
      </svg>
    </button>
  </div>


  {/* Drawer */}
  {isDrawerOpen && (
    <div className="md:hidden fixed inset-0 h-full w-full bg-gray-900 z-50 flex flex-col items-start p-4">
      <button onClick={toggleDrawer}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      {/* Navigation items */}
          <NavItem href="/" isActive={activeNavItem === '/'}>Home</NavItem>
          <NavItem href="/events" isActive={activeNavItem === '/events'}>Events</NavItem>
          <NavItem href="/business" isActive={activeNavItem === '/business'}>Business</NavItem>
          <NavItem href="/news" isActive={activeNavItem === '/news'}>News</NavItem>
          <NavItem href="/about" isActive={activeNavItem === '/about'}>About Us</NavItem>
          <NavItem href="/contact" isActive={activeNavItem === '/contact'}>Contact</NavItem>
           </div>
  )}
</div>

    </nav>
  );
};

export default Navbar;
