import data from "../../utils/Event/events_2.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../Common/carousel.jsx";

const youthInAction = () => {
  const events = data;
  console.log(events[0].image);
  return (
    <div className="container mx-auto px-4 md:px-36 md:pt-20 w-full">
      <h2 className="text-center text-5xl md:text-6xl font-semibold text-gray-800 my-8 md:my-12 ">
        Youth in Action
      </h2>
      {events.slice(0, 1).map((event) => (
        <div
          key={event.id}
          className="relative rounded-2xl overflow-hidden h-[600px] w-full md:w-[90%] lg:w-[100%] " // Responsive height adjustments
        >
          <video
            className="absolute inset-0 w-full h-full object-cover"
            controls
          >
            <source src={event.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute bottom-80 w-full h-20 inset-0 bg-black bg-opacity-60 flex flex-col justify-center p-6 text-left">
            <span className="text-white text-2xl">{event.title}</span>
          </div>
        </div>
      ))}

      <div>
        <Carousel event={events} />
      </div>
    </div>
  );
};

export default youthInAction;
