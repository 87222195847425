import React from 'react';
import hero from '../../assets/Hero/01.webp';

const HeroSection = () => {
  return (
    <div className="flex flex-col lg:flex-row mx-4 lg:mx-24">      
      {/* Left Side - Text */}
      <div className="flex-1 flex flex-col justify-center items-start lg:w-1/2">
        <h1 className="text-5xl lg:text-8xl font-semibold mb-4 text-left">Sri Lankan Youth Club of Hong Kong</h1>
        <p className="text-sm lg:text-lg mb-6 text-left">Youth of Sri Lanka are also having an undertaking for economic and social upliftment of the country.</p>
        <button className="bg-[#3C64C8] text-white rounded-full py-3 px-6 hover:bg-blue-700">BECOME A MEMBER</button>
      </div>
      
      {/* Right Side - Image */}
      <div className="lg:flex-1 lg:w-1/2 relative mt-8 lg:mt-0">
         <img
            src={hero}
            alt={`Hero`}
            className={`w-full h-auto lg:h-full object-cover`}
          />
      </div>
    </div>
  );
};

export default HeroSection;

