import React, { useState, useEffect } from 'react';
import data from '../../utils/businesses.json'

const SLBusinessesHK = () => {
  const [businesses, setBusinesses] = useState(data);
  return (
    <div className="container mx-auto px-4 md:px-36 pt-12 md:pt-20 w-full">
        <div className='flex justify-between items-center flex-col md:flex-row mb-4'> 
             <h2 className="text-3xl md:text-4xl font-medium mb-4 md:mb-4">Sri Lankan Business in HK </h2>
            <button className="hidden md:block px-6 py-2 bg-[#3C64C8] text-white rounded-full hover:bg-blue-700">
        BROWSE MORE
      </button>
        </div>
     
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {businesses.map((business, index) => (
          <div key={business.id} className="flex-1">
            <img className="rounded-t-lg" src={business.image} alt={business.name} />
            <div className="flex flex-col pt-2 rounded-b-lg text-left ">
                 <a 
        href="/your-link"
        className=" text-[#323232] text-xl font-medium group-hover:underline"
      >
        {business.name}
      </a>
       <a 
        href="/your-link"
        className=" text-[#323232] text-md group-hover:underline"
      >
        {business.description}
      </a>
       <a 
        href="/your-link"
        className=" text-[#323232] text-md group-hover:underline"
      >
        {business.location}
      </a>
            </div>
            {index === businesses.length - 1 && (
              <div className="md:hidden text-center mt-4">
                <button className="px-6 py-2 bg-[#3C64C8] text-white rounded-full hover:bg-blue-700">
                  BROWSE MORE
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

    </div>
  );
};

export default SLBusinessesHK;