import data from "../../utils/Event/events_1.json";
import Card from "../Common/card.jsx";

const currentDate = new Date().toLocaleDateString("en-US", {
  month: "long",
  day: "numeric",
  year: "numeric",
});

const mainSection = () => {
  const events = data;
  console.log(events[0].image);

  return (
    <div className="container mx-auto px-4 md:px-36 md:pt-20 w-full">
      {events.slice(0, 1).map((event) => (
        <div
          key={event.id}
          className="relative rounded-2xl overflow-hidden h-[500px] w-full md:w-[90%] lg:w-[100%] "
        >
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={event.image}
            alt={event.title}
          />
          <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center p-6 text-left">
            <div className="w-full md:w-1/2 mt-20">
              <h2 className="text-white text-5xl mt-8  mb-4  ">
                {event.title}
              </h2>
              <p className="text-white text-xl mb-4 d">{event.title2}</p>
              <p className="w-30 text-white text-[13px] leading-loose mb-6 ">
                {event.title3}
              </p>
              <div className="flex-col space-x-6 mt-10">
                <button className="bg-blue-500  hover:bg-blue-600 text-white  py-3 px-10 rounded-full shadow transition duration-300">
                  <img
                    src="../assets/Events/Location.webp"
                    alt="Location"
                    className="w-5 h-6 mr-2 inline-block"
                  />
                  <span className="text-[10px]">VIEW LOCATION</span>
                </button>
                <button className="bg-blue-500 hover:bg-blue-600 text-white  py-3 px-8 rounded-full shadow transition duration-300">
                  <img
                    src="../assets/Events/Users.webp"
                    alt="user_icon"
                    className="w-5 h-6 mr-2 inline-block"
                  />
                  <span className="text-[10px]">REGISTER</span>
                </button>
              </div>
              <div className="text-[13px] absolute bottom-20 right-0 text-white text-sm p-4">
                {currentDate}
              </div>
            </div>
          </div>
        </div>
      ))}

      <h2 className="text-left text-5xl md:text-6xl font-semibold text-gray-800 my-8 md:my-12">
        Upcoming Events
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4 md:my-8">
        {/* Map through your events data and create cards for each event */}
        {events.slice(1).map((event) => (
          <Card key={event.id} event={event} />
        ))}
      </div>
    </div>
  );
};

export default mainSection;
