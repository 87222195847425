import React from "react";
import "../App.css";
import HeroSection from "../components/Event/mainSection";
import Youth from "../components/Event/youthInAction";
import Donate from "../components/Event/donateSec";
import OtherEvents from "../components/Event/otherEvents";
import Subscribe from "../components/Event/subscribe";

function EventsPage() {
  return (
    <div>
      <HeroSection />
      <Youth />
      <Donate />
      <OtherEvents />
      <Subscribe />
    </div>
  );
}

export default EventsPage;
