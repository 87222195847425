import React from "react";
import data from "../../utils/team.json";

const Team = ({ title }) => {
  const team = data;
  return (
    <div className="container mx-auto px-4 md:px-36 pt-12 md:pt-20 w-full">
      <h2 className="text-center text-4xl font-medium mb-4">{title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
        {team.map((member) => (
          <div key={member.id} className="flex-1">
            <img
              className="rounded-t-lg"
              src={member.image}
              alt={member.title}
            />
            <div className="flex flex-col pt-2 rounded-b-lg ">
              <a
                href="/your-link"
                className="text-center md:text-left text-[#323232] text-lg font-medium group-hover:underline"
              >
                {member.role}
              </a>
              <a
                href="/your-link"
                className="text-center md:text-left text-[#323232] text-2xl font-medium group-hover:underline"
              >
                {member.name}
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-8"></div>
    </div>
  );
};

export default Team;
