import React from "react";
import logo from "../../assets/footer-logo.webp";

const Footer = () => {
  return (
    <footer className="bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 flex flex-col md:flex-row justify-between">
        <div className="md:w-2/3 md:pr-20 mb-6 md:mb-0 flex flex-col justify-center items-start text-left">
          <img src={logo} alt="Logo" className="mb-4" />
          <p>
            Our youth club provides critical programs to empower Sri Lanka's
            next generation. From vocational training to computer skills
            courses, your donation helps give our youth the tools to succeed. No
            matter the amount, your contribution supports young leaders and
            changes lives in our community.
          </p>
          <button className="bg-[#3C64C8] mt-4 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300">
            BECOME A MEMBER
          </button>
        </div>

        <div className="text-left grid grid-cols-2 md:grid-cols-4 gap-8 text-sm">
          <div>
            <h5 className="font-bold mb-3">Pages</h5>
            <ul className="text-fGray">
              <li>Home</li>
              <li>Events</li>
              <li>Charity</li>
              <li>Business</li>
              <li>News</li>
              <li>Members</li>
              <li>About us</li>
              <li>Contact</li>
            </ul>
          </div>
          <div>
            <h5 className="font-bold mb-3">About Us</h5>
            <ul className="text-fGray">
              <li>Perspective</li>
              <li>Our Story</li>
              <li>Our Vision</li>
              <li>Collaborate</li>
            </ul>
          </div>
          <div>
            <h5 className="font-bold mb-3">Resources</h5>
            <ul className="text-fGray">
              <li>Resource Library</li>
              <li>The Cultivator</li>
            </ul>
          </div>
          <div>
            <h5 className="font-bold mb-3">Connect</h5>
            <ul className="text-fGray">
              <li>Contact</li>
              <li>LinkedIn</li>
              <li>Instagram</li>
              <li>Facebook</li>
              <li>Youtube</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
