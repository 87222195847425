import React from "react";

const Card = ({ event }) => {
  return (
    <div
      key={event.id}
      className="w-full bg-white rounded-xl shadow-lg flex flex-col relative"
    >
      <img
        className="rounded-xl w-full object-cover h-72 md:h-auto"
        src={event.image}
        alt={event.title}
      />
      <div className="flex flex-col justify-end text-left w-full rounded-2xl bg-gradient-to-b from-transparent to-black/70 absolute bottom-0 left-0 px-4 py-4 text-white h-full">
        <a
          href="{event.link}"
          className="text-lg font-medium group-hover:underline"
        >
          {event.title}
        </a>
        <p className="hidden md:block text-white text-sm">{event.date}</p>
      </div>
    </div>
  );
};

export default Card;
