import React from "react";
import data from "../../utils/Business/otherBusiness.json";
import CustomButton from "../Common/button";

const OtherEvents = () => {
  const events = data;
  return (
    <div className="container mx-auto px-4 md:px-36 pt-6 md:pt-20 w-full">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {events.map((event) => (
          <div
            key={event.id}
            className="flex flex-col bg-white rounded-lg overflow-hidden h-full"
          >
            <img
              className="rounded-t-lg w-full object-cover" // Adjust the height directly in your CSS if needed
              src={event.image}
              alt={event.title}
            />
            <div className="flex flex-col justify-between flex-grow p-4">
              <div className="mb-auto">
                <h5 className="text-xl line-clamp-2">{event.title}</h5>
                <p className="text-base mt-4 line-clamp-3">
                  {event.description}
                </p>
              </div>
              <div className="pt-4">
                <div className="mb-2">
                  <span>{event.phoneNumber}</span>
                </div>
                <div className="flex items-center">
                  <img
                    src="../assets/Business/direction.webp"
                    alt="Direction"
                    className="w-5 h-5"
                  />
                  <span className="ml-1 text-[#3C64C8]">DIRECTION</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-6 mb-6">
        <CustomButton text="LOAD MORE" onClick={undefined} />
      </div>
    </div>
  );
};

export default OtherEvents;
