import React from "react";
import Mainsection from "../components/News/mainSection";
import Secondarysection from "../components/News/secondarySection";
import Subscribe from "../components/Event/subscribe";

function NewsPage() {
  return (
    <div>
      <Mainsection />
      <Secondarysection />
      <Subscribe />
    </div>
  );
}

export default NewsPage;
