import React from "react";
import Mainsection from "../components/Business/mainSection";
import Otherbusiness from "../components/Business/otherBusiness";
import Subscribe from "../components/Event/subscribe";

function BusinessPage() {
  return (
    <div>
      <Mainsection />
      <Otherbusiness />
      <Subscribe />
    </div>
  );
}

export default BusinessPage;
