import React, { useState, useEffect } from 'react';
import data from '../../utils/events.json'
import nextIcon from '../../assets/NextIcon.webp'

const EventsHighlights = () => {
  const [events, setEvents] = useState(data);
console.log(events[0].image)
  return (
    <div className="container mx-auto px-4 md:px-36 md:pt-20 w-full">
      <h2 className="text-center text-5xl md:text-6xl font-semibold text-gray-800 my-8 md:my-12">Our 2023 Events Highlights</h2>
      {events.slice(0, 1).map((event) => (
  <div key={event.id} className="relative">
    <img className='rounded-2xl w-full object-cover h-72 md:h-auto' src={event.image} alt={event.title} />
   <div className='flex justify-between items-end w-full rounded-b-2xl bg-black/40 absolute bottom-0 left-0 px-4 py-4'>
      <a 
        href="/your-link" // Change this to your desired link
        className="text-white  md:text-[38px] font-medium w-full md:w-2/3 text-left group-hover:underline"
      >
        {event.title}
      </a>
       <p className="hidden md:block text-white">{event.date}</p>
    </div>
  </div>
))}

       <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4 md:my-8">
        {/* Map through your events data and create cards for each event */}
        {events.slice(1).map((event) => (
          <div key={event.id} className="w-full bg-white rounded-xl shadow-lg flex flex-col relative">
            <img className="rounded-xl w-full object-cover h-72 md:h-auto" src={event.image} alt={event.title} />
            
            <div className="flex flex-col justify-end text-left w-full rounded-2xl bg-gradient-to-b from-transparent to-black/70 absolute bottom-0 left-0 px-4 py-4 text-white h-full">
  
   <a 
        href="/your-link" // Change this to your desired link
        className="text-lg font-medium group-hover:underline"
      >
        {event.title}
      </a>
 <p className="hidden md:block text-white text-sm">{event.date}</p>
</div>

          </div>
        ))}

        {/* The 'See All' card */}
        <div className="w-full bg-[#3C64C8]/10 rounded-xl shadow-lg flex items-end justify-start md:justify-center text-[#3C64C8]">
          <div className="text-left px-4 py-8 md:p-4">
            <p className="text-lg font-medium">See All Our Charity Events</p>
            {/* Replace "#" with the link to all events */}
             <button onClick={() => {/* Handle click event here */}}>
        <img src={nextIcon} alt="Next" className="w-8 md:w-6 w-8 md:h-6" /> {/* Adjust size as needed */}
      </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsHighlights;
