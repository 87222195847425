import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Common/navbar';
import Footer from './components/Common/footer';
import Home from './pages/home-page';
import EventsPage from './pages/events';
import BusinessPage from './pages/business-page';
import NewsPage from './pages/news-page';
import AboutPage from './pages/about-page';
import ContactPage from './pages/contact-page';

function App() {
  return (
    <div>
       <Router>
        <Navbar/>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/events" element={<EventsPage/>} />
            <Route path="/business" element={<BusinessPage/>} />
            <Route path="/news" element={<NewsPage/>} />
            <Route path="/about" element={<AboutPage/>} />
            <Route path="/contact" element={<ContactPage/>} />
          </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
