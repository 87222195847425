import React from 'react';
// Replace this with the path to your actual image
import supportImage from '../../assets/Donate/1.webp';

const DonateSec = () => {
  return (
   <div className='container mx-auto px-4 md:px-36 pt-12 md:pt-20 w-full'>
    <div className="flex flex-col md:flex-row md:p-8 items-center md:bg-[#F4F4F4] rounded-2xl md:shadow-lg overflow-hidden">
      <div className="md:w-1/2 md:p-8 text-left">
        <h2 className="text-4xl font-medium text-gray-800 mb-4">Together We Can Change Lives</h2>
        <p className="text-gray-600 mb-6">
          Give what you can today to help provide critical services and uplift those in need.
          By donating any amount, you join our mission to transform lives for the better.
          Let's empower people - together. Thank you!
        </p>
        <button className="bg-[#3C64C8] text-white px-8 py-4  rounded-full hover:bg-blue-600">
          DONATE NOW
        </button>
      </div>
      <div className="py-8 md:p-12 item-end md:w-3/5">
        <img src={supportImage} alt="Support"  />
      </div>
    </div>
   </div>
  );
};

export default DonateSec;
