import React from "react";

function TextSection() {
  return (
    <>
      <div className="text-center my-8 md:my-12">
        <h2 className="text-5xl md:text-6xl font-semibold">
          The story behind our company
        </h2>
      </div>
      <div className="py-6 md:py-12 bg-white mx-5">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 text-center md:text-left">
          <div className="flex flex-col justify-center  md:items-start">
            <p>
              The Positive Futures Youth Club has been serving the youth of our
              community since 1968. Our mission is to empower young people ages
              6-24 by providing a safe, inclusive space to explore their
              interests, develop important life skills, and unlock their
              potential. <br /> At our club, teens and pre-teens can get
              homework help and tutoring, play sports and games, express their
              creativity through arts, music, and media programs, and
              participate in STEM activities that spark curiosity.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:items-start">
            <p>
              We also offer workshops on leadership, financial literacy, public
              speaking, interview skills, and more to help prepare youth for
              bright futures. <br /> <br />
              Mentoring, counseling services, and healthy meals provide
              additional support. Special events, field trips, and community
              service opportunities inspire learning, growth, and friendship.
              With dedicated staff, volunteers, and community partners, our club
              celebrates the vibrant diversity of our youth.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextSection;
