import React from "react";

const CustomButton = ({ text, onClickHandler, width, px, py, height }) => {
  // Apply default values if none are provided
  const buttonWidth = width || "w-auto"; // Default to 'w-auto' if no width is provided
  const paddingX = px || "px-8"; // Default padding-x
  const paddingY = py || "py-4"; // Default padding-y
  const buttonHeight = height || "h-auto"; // Default height

  return (
    <button
      className={`bg-[#3C64C8] text-white ${paddingX} ${paddingY} ${buttonHeight} ${buttonWidth} flex items-center justify-center rounded-full hover:bg-blue-600`}
      onClick={onClickHandler}
    >
      {text}
    </button>
  );
};

export default CustomButton;
