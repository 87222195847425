import React from "react";

const financialMetrics = ({ goal = 0, raised = 0 }) => {
  const toGo = goal - raised;

  return (
    <div className=" font-semibold text-lg flex mr-5 justify-between">
      <div>
        <span className="text-gray-800">Goal</span>
        <br />
        <span className="text-red-600">${goal.toLocaleString()}</span>
      </div>
      <div className="border-l border-gray-400 pl-4">
        <span className="text-gray-800">Raised</span>
        <br />
        <span className="text-yellow-500">${raised.toLocaleString()}</span>
      </div>
      <div className="border-l border-gray-400 pl-4">
        <span className="text-gray-800">To Go</span>
        <br />
        <span className="text-blue-600">${toGo.toLocaleString()}</span>
      </div>
    </div>
  );
};

export default financialMetrics;
