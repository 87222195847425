import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data from "../../utils/Event/events_2.json";
import Card from "./card";

const events = data;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 z-10 bg-transparent"
    style={{ top: "50%", transform: "translate(100%, -50%)" }}
  >
    <img
      src="../../assets/Events/vectorLeft.webp"
      alt="Previous"
      style={{ width: "20px", height: "20px" }}
    />
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 z-10 bg-transparent"
    style={{ top: "50%", transform: "translate(-100%, -50%)" }}
  >
    <img
      src="../../assets/Events/vectorRight.webp"
      alt="Next"
      style={{ width: "20px", height: "20px" }}
    />
  </button>
);

export default function SimpleSlider() {
  return (
    <Carousel
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay
      autoPlaySpeed={4000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px gap-4 px-4 md:gap-8 mt-8 md:mt-0"
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
    >
      {events.slice(1).map((event, index) => (
        <div key={event.id} className=" md:my-8">
          <Card event={event} />
        </div>
      ))}
    </Carousel>
  );
}
