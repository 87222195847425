import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data from "../../utils/Business/slider.json";
import CustomButton from "../Common/button";

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 z-10 bg-transparent"
    style={{ top: "50%", transform: "translate(100%, -50%)" }}
  >
    <img
      src="../../assets/Events/vectorLeft.webp"
      alt="Previous"
      style={{ width: "20px", height: "20px" }}
    />
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 z-10 bg-transparent"
    style={{ top: "50%", transform: "translate(-100%, -50%)" }}
  >
    <img
      src="../../assets/Events/vectorRight.webp"
      alt="Next"
      style={{ width: "20px", height: "20px" }}
    />
  </button>
);

const mainSection = () => {
  const events = data;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="container mx-auto px-4 md:px-36 md:pt-20 w-full pb-12 ">
      <Carousel
        responsive={responsive}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        additionalTransfrom={0}
        arrows
        infinite
        autoPlay
        autoPlaySpeed={4000}
      >
        {events.map((event) => (
          <div
            key={event.id}
            className="relative rounded-2xl overflow-hidden h-[350px] w-full md:w-[90%] lg:w-[100%]"
          >
            <img
              className="absolute inset-0 w-full h-full object-cover"
              src={event.image}
              alt={event.title}
            />
            <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center p-6 text-left ">
              <div className="w-full md:w-1/2 p-4 md:p-10 ml-10 md:ml-20 ">
                <h2 className="text-white text-3xl mt-8 mb-4 font-bold overflow-wrap">
                  {event.title}
                </h2>
                <p className="w-full text-white text-[13px] md:text-base lg:text-lg leading-loose md:leading-relaxed lg:leading-normal font-normal">
                  {event.address}
                </p>
                <p className=" text-white text-[13px] leading-loose font-normal">
                  {event.phoneNumber}
                </p>
                <div className="flex-col mt-4 md:mt-10">
                  <CustomButton
                    text="DIRECTION"
                    onClick={undefined} // Add the respective onClick handler here
                    width="w-40"
                    px="px-10"
                    py="py-3"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default mainSection;
