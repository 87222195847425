import React from "react";
import MainSection from "../components/AboutUs/mainSection";
import Subscribe from "../components/Event/subscribe";
import Textsection from "../components/AboutUs/textSection";
import BecomeMember from "../components/AboutUs/becomeMember";
import Teams from "../components/Home/teams";

function AboutPage() {
  return (
    <div>
      <MainSection />
      <Textsection />
      <BecomeMember />
      <div className="hidden md:block">
        <Teams />
      </div>
      <Subscribe />
    </div>
  );
}

export default AboutPage;
