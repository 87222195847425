import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="w-full bg-gray-300 rounded-full h-5 overflow-hidden mt-4">
      <div
        style={{ width: `${progress}%` }}
        className="bg-blue-600 h-full transition-all ease-out duration-300 rounded-full"
      ></div>
    </div>
  );
};

export default ProgressBar;
