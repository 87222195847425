import React from "react";
// Import the video file if it's stored locally, otherwise set the video URL directly in the video src attribute
import videoFile from "../../assets/vid.mp4";

const VideoComponent = () => {
  return (
    <div className="container mx-auto px-4 md:px-36 pt-12 md:pt-20 w-full">
      <div className="relative rounded-2xl overflow-hidden">
        {/* Video Player */}
        <video className="w-full" controls src={videoFile}>
          Your browser does not support the video tag.
        </video>

        {/* Video Title Overlay */}
        {/* <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-transparent to-transparent p-4">
        <h2 className="text-white text-xl font-bold">Reach for the Stars Talent Show</h2>
      </div> */}

        {/* More Videos Button */}
        <div className="text-center mt-4">
          <button className="px-6 py-2 bg-[#3C64C8] text-white rounded-full hover:bg-blue-700">
            MORE VIDEOS
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoComponent;
