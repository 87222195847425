import data from "../../utils/News/articles_2.json";

const Secondarysection = () => {
  const articles = data;
  return (
    <div className="container mx-auto px-4 md:px-36 pt-6 md:pt-20 w-full">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {articles.map((article, index) => (
          <div key={article.id} className="flex flex-col bg-white  rounded-lg">
            <img
              className="rounded-t-lg w-full"
              src={article.image}
              alt={article.title}
            />
            <div className="p-4 text-left">
              <a
                href="/your-link" // Replace with actual link
                className="text-[#323232] text-lg md:text-xl font-medium group-hover:underline"
              >
                {article.title}
              </a>
              <p className="mt-2 text-sm md:text-base text-gray-600">
                {article.description}
              </p>
            </div>
            {index === articles.length - 1 && (
              <div className="md:hidden text-center mt-4">
                <button className="px-6 py-2 bg-[#3C64C8] text-white rounded-full hover:bg-blue-700">
                  BROWSE MORE
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Secondarysection;
