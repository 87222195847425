import React, { useState, useEffect } from 'react';
import data from '../../utils/activities.json'

const JuniorActivities = () => {
  const [activities, setActivities] = useState(data);
  return (
    <div className="container mx-auto px-4 md:px-36 pt-12 md:pt-20 w-full">
        <div className='flex justify-between items-center flex-col md:flex-row mb-4'> 
             <h2 className="text-3xl md:text-4xl font-medium mb-4 md:mb-4">Junior Activities </h2>
            <button className="hidden md:block px-6 py-2 bg-[#3C64C8] text-white rounded-full hover:bg-blue-700">
        BROWSE MORE
      </button>
        </div>
     
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {activities.map((activity, index) => (
          <div key={activity.id} className="flex flex-col">
            <img className="rounded-t-lg w-full" src={activity.image} alt={activity.title} />
            <div className="py-2 md:p-4 text-left">
                 <a 
        href="/your-link"
        className=" text-[#323232] text-lg md:text-xl font-medium group-hover:underline"
      >
        {activity.title}
      </a>
            </div>
            {index === activities.length - 1 && (
              <div className="md:hidden text-center mt-4">
                <button className="px-6 py-2 bg-[#3C64C8] text-white rounded-full hover:bg-blue-700">
                  BROWSE MORE
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

    </div>
  );
};

export default JuniorActivities;