import React from "react";

const subscribe = () => {
  return (
    <div className="container mx-auto px-4 md:px-36 pt-12 md:pt-20 pb-12 w-full">
      <div className=" hidden md:flex flex-col md:flex-row  items-center md:bg-[#F4F4F4] rounded-3xl  overflow-hidden h-84 ">
        <div className="md:w-5/6 md:p-8 text-left">
          <h2 className="text-2xl md:text-4xl font-bold text-gray-800 mb-4 ">
            Subscribe to our newsletter
          </h2>
          <p className="text-gray-600 mb-6">
            Subscribe for news on our life-changing programs.
          </p>
          <form
            action="#"
            method="POST"
            className="flex flex-col md:flex-row items-center gap-4"
          >
            <input
              type="email"
              placeholder="Enter your email address"
              className="px-8 py-4 w-2/3 md:w-8/12 rounded-full border border-gray-300"
              required
            />
            <button className="bg-[#3C64C8] text-white px-8 py-4 rounded-full w-1/3 md:w-3/12 hover:bg-blue-600">
              SUBSCRIBE
            </button>
          </form>
        </div>
        <div className="md:w-1/2 flex justify-center items-center ">
          <img
            src="../../../assets/Hero/01.webp"
            alt="Newsletter Visual"
            className="rounded-xl h-200 object-cover scale-125 "
          />
        </div>
      </div>
    </div>
  );
};

export default subscribe;
