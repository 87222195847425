import React from "react";
import Button from "../Common/button";

function BecomeMember() {
  return (
    <>
      <div className="text-center my-8 md:my-12 ">
        <h2 className="text-5xl md:text-6xl font-semibold ">
          Guiding youth with heart.
        </h2>
        <p className="mt-4 text-2xl font-medium px-8 md:px-32 mx-6">
          Get to know the incredibly talented, dedicated, and passionate
          individuals who make up our staff, volunteers, and mentors - the heart
          behind our mission to empower youth.
        </p>
        <div className="mt-10 hidden md:block">
          <div class="w-full inline-flex flex-nowrap">
            <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
              <li>
                <img
                  src="../assets/About/2.webp"
                  alt="HONG KONG"
                  className="mx-auto"
                />
              </li>
            </ul>
            <ul
              class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll "
              aria-hidden="true"
            >
              <li>
                <img
                  src="../assets/About/2.webp"
                  alt="HONG KONG"
                  className="mx-auto"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center mt-10 ">
          <Button text="BECOME A MEMBER" />
        </div>
      </div>
    </>
  );
}

export default BecomeMember;
