import React from 'react';
import GroupImg from '../../assets/YouthClubSection/Group 49.png'

const YouthClubSection = () => {
  return (
    <div className="relative mt-12 lg:mt-20 flex flex-col lg:flex-row w-full overflow-hidden">
      <div className='w-[200%] p-2 pr-14 lg:pr-2'>
        <img src={GroupImg} alt="Youth Club" className="lg:w-full" />
      </div>
      
      <div className="lg:absolute lg:top-0 left-0 lg:left-1/2 w-full lg:w-2/5 flex flex-col mt-4 lg:mt-20 lg:mr-20 items-start p-4 lg:p-12 lg:p-16 space-y-2">
       <h2 className="text-5xl lg:text-6xl font-semibold text-gray-800">About our youth club.</h2>
          <p className="text-gray-600">
           Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum.
           </p>
        <button className="bg-[#3C64C8] text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300">
             Learn More           
             </button>
      </div>
    </div>
  );
};

export default YouthClubSection;
