import React from "react";

function StatsCard() {
  return (
    <>
      <div className="text-center my-8 md:my-12">
        <h2 className="text-5xl md:text-6xl font-semibold">About Us</h2>
        <p className="mt-4 text-2xl font-medium md:px-48 mx-2">
          Empowering HK youth since 1968. Providing safe, nurturing programs for
          50,000+ youth to develop skills and friendships. Celebrating
          diversity. Join us in shaping future generations.
        </p>
      </div>
      <div className="flex justify-center md:p-5 bg-white md:h-500">
        <div className="w-full  max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-6 mx-32">
          <div className="hidden md:block md:col-span-1">
            <img
              src="../assets/About/1.webp"
              alt="Collaborative Hands"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          <div className="md:col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-4 h-full order-1">
            <div className="flex flex-col items-center justify-center bg-white rounded-lg p-8 h-full border border-grey-300">
              <p className="text-6xl font-semibold">1,000+</p>
              <p className="text-md text-gray-600 mx-2 md:mx-10 text-center mt-8">
                youth participate in programs each year
              </p>
            </div>
            <div className="flex flex-col items-center justify-center bg-[#E5AE34] text-white rounded-lg p-8 h-full order-2">
              <p className="text-6xl font-semibold">60%</p>
              <p className="text-md text-center mt-8">
                of recent grads were hired for jobs due to skills gained at club
              </p>
            </div>
            <div className="flex flex-col items-center justify-center bg-[#4061B8] text-white rounded-lg p-8 h-full order-4 md:order-3">
              <p className="text-6xl font-bold">300,000</p>
              <p className="text-md text-center px-5 md:px-20 mt-8">
                meals provided through food assistance program per year
              </p>
            </div>
            <div className="flex flex-col items-center justify-center bg-white rounded-lg p-8 h-full border border-gray-300 order-3 md:order-4">
              <p className="text-6xl font-semibold">5,000+</p>
              <p className="text-md text-gray-600 text-center px-5 md:selection:px-20 mt-8">
                youth served through our programs since 2021
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatsCard;
