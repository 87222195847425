import { Gallery } from "react-grid-gallery";
import data from "../../utils/News/articles_1.json";

const MainSection = () => {
  const images = data.map((item) => ({
    src: item.image,
    width: item.width,
    height: item.height,
    nano: null,
  }));

  return (
    <div className="container mx-auto px-4 md:px-36 py-4 md:py-8">
      <div className="text-center mb-8 md:mb-12">
        <h2 className="text-5xl md:text-6xl font-semibold text-gray-800">
          Articles & Resources
        </h2>
        <p className="mt-4 text-xl font-semibold text-gray-600">
          Discovering the latest trends, approaches, and ideas for supporting
          youth on their journey to gaining knowledge, skills, strength of
          character, and belief in their potential.
        </p>
      </div>
      <div>
        <Gallery
          images={images}
          enableImageSelection={false}
          rowHeight={200}
          maxRows={3}
          thumbnailImageComponent={ImageComponent}
          margin={5}
        />
      </div>
    </div>
  );
};

export default MainSection;

const ImageComponent = (props) => {
  return (
    <div className="bg-white">
      <div className="overflow-hidden rounded-[15px] ">
        <img className="rounded-lg " alt="img" {...props.imageProps} />
      </div>
    </div>
  );
};
