import React from 'react'

function ContactPage() {
  return (
    <div>
       <header className="h-screen flex flex-col justify-center">
       <h1 className=' text-black text-[80px]'>Welcome to Sri Lankan Youth Club of Hong Kong!</h1>
       <div className='text-4xl'>Stay Tuned as We Craft Something Amazing for You. Coming Soon!</div>
      </header>
    </div>
  )
}

export default ContactPage