import React from "react";
import ArticlesAndNews from "../components/Home/articles-news";
import DonateSec from "../components/Home/donate-sec";
import EventsHighlights from "../components/Home/events-highlight";
import HeroSection from "../components/Home/hero-section";
import JuniorActivities from "../components/Home/junior-activities";
import SLBusinessesHK from "../components/Home/sl-businesses";
import Team from "../components/Home/teams";
import VideoComponent from "../components/Home/video-player";
import YouthClubSection from "../components/Home/youth-club-section";
import Button from "../components/Common/button";

export default function Home() {
  return (
    <>
      <HeroSection />
      <YouthClubSection />
      <EventsHighlights />
      <ArticlesAndNews />
      <Team title="Meet our team" />
      <div className="flex justify-center mt-5">
        <Button text="SEE ALL MEMBERS" />
      </div>
      <JuniorActivities />
      <SLBusinessesHK />
      <VideoComponent />
      <DonateSec />
    </>
  );
}
